<template>
    <header class="header-holder hideatmobile">
        <div class="menu-wrapper center-relative relative">
            <div class="header-logo">
                <a href="#alku">
                    <div style="padding: 0.5rem; display: inline-block; border: #fff 1px solid; ">NSW</div>
                    <div style="padding: 0.2rem; display: inline-block; "></div>
                    <div style="padding: 0.5rem; display: inline-block; border: #fff 1px solid; ">ENGINEERING</div>
                    <div style="padding: 0.2rem; display: inline-block; "></div>
                    <div style="padding: 0.5rem; display: inline-block; border: #fff 1px solid; ">OY</div>
                </a>
            </div>
            <div class="toggle-holder hideatmobile">
                <div id="toggle" class="hideatmobile">
                    <div class="first-menu-line"></div>
                    <div class="second-menu-line"></div>
                    <div class="third-menu-line"></div>
                </div>
            </div>
            <div class="menu-holder">
                <nav id="header-main-menu">
                    <ul class="main-menu sm sm-clean">
                        <li>
                            <a href="#yritys">Yritys</a>
                        </li>
                        <li>
                            <a href="#ndt-testauspalvelut">Palvelut</a>
                        </li>
                        <li>
                            <a href="#ota-yhteytta">Ota yhteyttä</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="clear"></div>
        </div>
    </header>

    <div id="content" class="site-content center-relative">
        <div id="yritys" class="section">
            <div class="alphalayer alpha-yritys">
                <div class="section-wrapper block content-1170 center-relative">
                    <div class="content-wrapper">
                        <div class="one_half">
                            <p class="title-description-up">
                                NSW ENGINEERING OY
                            </p>
                            <h2 class="entry-title medium-text" style="color:#fff; font-size: 3.2rem">
                                Askeleen edellä, ainetta rikkomatta.</h2>
                            <div style="font-size: 1.1rem;">
                                Asiakkaamme kykenevät tekemään päätöksiä sekä oikein ajoitettuja toimenpiteitä faktoihin
                                perustuen. NDT-palveluitamme hyödynnetään mm. painelaitteiden sekä teräsrakenteiden
                                valmistuksen laadunseurannassa, kunnossapidon suunnittelussa sekä teollisuuden
                                prosessien keskeytyksien ennaltaehkäisyssä.</div>
                            <div style="margin: 1.5rem 0px; font-size: 1.1rem;">Olemme erikoistuneet löytämään
                                asiakkaidemme tuotteista, komponenteista, sekä materiaaleista pienimmätkin poikkeamat,
                                ennen kuin niistä seuraa vahinkoa liiketoiminnalle.</div>
                            <div style="margin: 1.5rem 0px; font-size: 1.1rem;">Toimipisteemme sijaitsee Kokkolassa,
                                Keski-Pohjanmaalla, mutta asiantuntijamme ovat käytettävissä läpi Suomen.</div>

                            <div class="button-holder text-left">
                                <a href="#ndt-testauspalvelut" class="button">
                                    NDT-TESTAUSPALVELUT
                                </a>
                            </div>
                        </div>
                        <div class="one_half last hideatmobile" data- threshold="0 0" data-jarallax-element="120 0"
                            style="text-align:center; align:right; vertical-align:middle;">

                            <div class=""
                                style="border: red 2px solid; display:inline-block; padding: 2rem; position: absolute; right:0px; width:250px; height:200px;">
                                <p class="service-num"><i class="fas fa-phone"></i></p>
                                <div class="service-txt"><a href="tel:+358449440519">
                                        <h3>Jari Räisänen<br>
                                            044 944 0519</h3>
                                    </a>
                                </div>
                            </div>
                            <div class=""
                                style="border: red 2px solid; display:inline-block; width:250px; height:200px; padding: 2rem; position: absolute; right:0px; top: 300px;">
                                <p class="service-num"><i class="fas fa-phone"></i></p>
                                <div class="service-txt">
                                    <a href="tel:+358503210658">
                                        <h3>Teijo Parttimaa<br>
                                            050 321 0658</h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ndt-testauspalvelut Section -->
        <div id="ndt-testauspalvelut" class="section">
            <div class="alphalayer alpha-ndt-testauspalvelut">
                <div class="page-title-holder hideatmobile">
                    <h3 class="entry-title">
                        PALVELUT
                    </h3>
                </div>
                <div class="section-wrapper block content-1170 center-relative">
                    <div class="content-wrapper">
                        <div class="one_half">
                            <p class="title-description-up">
                                NDT-TESTAUSPALVELUT
                            </p>
                            <h2 class="entry-title medium-text" style="color:#fff;">
                                Ammattitaidolla suunnittelusta raportointiin.</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">Kattavan testivalikoimamme avulla
                                pystymme vastaamaan haastaviinkin asiakastarpeisiin.</div>

                            <div class="button-holder text-left ">
                                <a href="#ota-yhteytta" class="button">
                                    OTA YHTEYTTÄ
                                </a>
                            </div>
                        </div>
                        <div class="one_half last content-wrapper hideatmobile" data-threshold="0 0"
                            data-jarallax-element="120 0">
                            <div class="one_half innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="fas fa-radiation"></i></p>
                                    <div class="service-txt">
                                        <h4>Teollisuusradiografia</h4>

                                    </div>
                                </div>
                            </div>

                            <div class="one_half last innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="fas fa-wave-square"></i></p>
                                    <div class="service-txt">
                                        <h4>Ultraäänitarkastus</h4>


                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="one_half innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="fas fa-magnet"></i></p>
                                    <div class="service-txt">
                                        <h4>Magneettijauhetarkastus</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="one_half last innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="fas fa-microscope"></i></p>
                                    <div class="service-txt">
                                        <h4>Paksuusmittaus</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="one_half innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="far fa-eye"></i></p>
                                    <div class="service-txt">
                                        <h4>Silmämääräinen tarkastus</h4>
                                    </div>
                                </div>
                            </div>

                            <div class="one_half last innerhalf">
                                <div class="service-holder">
                                    <p class="service-num"><i class="fas fa-beer"></i></p>
                                    <div class="service-txt">
                                        <h4>Tunkeumanestetarkastus</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>


                    </div>
                </div>
            </div>
        </div>


        <!-- ota-yhteytta Section -->
        <div id="ota-yhteytta" class="section">
            <iframe class="hideatmobile" id="mapframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33288.55085103172!2d23.23556780160739!3d63.82667078194882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468747b8d55314b5%3A0xb960b9474ec8f515!2sKoivistontie%2C%20Kokkola!5e0!3m2!1sfi!2sfi!4v1618960157883!5m2!1sfi!2sfi"
                loading="lazy"></iframe>
            <div class="alphalayer alpha-ota-yhteytta">
                <div class="page-title-holder hideatmobile">
                    <h3 class="entry-title">
                        OTA YHTEYTTÄ
                    </h3>
                </div>

                <div class="content-wrapper">
                    <div class="section-wrapper block content-1170 center-relative">
                        <div style="text-align:center; align:right; vertical-align:middle;">
                            <p class="title-description-up">PALVELEMME LÄPI SUOMEN</p>
                            <h2 class="entry-title medium-text smaller-in-mobile" style="color:#fff;">
                                NSW Engineering Oy</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">
                                <a class="smaller-in-mobile" v-if="!isAppleDevice" target="_blank"
                                    href="https://www.google.com/maps/place/Koivistontie%204+67700+Kokkola">Koivistontie
                                    4,
                                    67700 Kokkola</a></div>
                            <a class="smaller-in-mobile" v-if="isAppleDevice" target="_blank"
                                href="maps:www.google.com/maps/place/Koivistontie%204+67700+Kokkola">Koivistontie 4,
                                67700
                                Kokkola</a>
                        </div>

                        <div class="one_half" style="text-align:center; align:right; vertical-align:middle;">
                            <p class="title-description-up">TOIMITUSJOHTAJA</p>
                            <h2 class="entry-title medium-text smaller-in-mobile" style="color:#fff;">
                                Jari Räisänen</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">
                                <h2 class="smaller-in-mobile"><a href="tel:+358449440519">044 944 0519</a></h2>
                                <a class="smaller-in-mobile"
                                    href="mailto:jari.raisanen@nswengineering.fi">jari.raisanen@nswengineering.fi</a>
                            </div>

                            
                        </div>

                        <div class="one_half last" data- threshold="0 0" data-jarallax-element="120 0"
                            style="text-align:center; align:right; vertical-align:middle;">
                            <p class="title-description-up">PROJEKTIPÄÄLLIKKÖ</p>
                            <h2 class="entry-title medium-text smaller-in-mobile" style="color:#fff;">
                                Teijo Parttimaa</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">
                                <h2 class="smaller-in-mobile"><a href="tel:+358503210658">050 321 0658</a></h2>
                                <a class="smaller-in-mobile"
                                    href="mailto:teijo.parttimaa@nswengineering.fi">teijo.parttimaa@nswengineering.fi</a>
                            </div>


                        </div>

                        <div class="one_half" style="text-align:center; align:right; vertical-align:middle;">
                            <p class="title-description-up">NDT-TESTAAJA</p>
                            <h2 class="entry-title medium-text smaller-in-mobile" style="color:#fff;">
                                Riku Rajala</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">
                                <h2 class="smaller-in-mobile"><a href="tel:+358442384658">044 238 4658</a></h2>
                                <a class="smaller-in-mobile"
                                    href="mailto:riku.rajala@nswengineering.fi">riku.rajala@nswengineering.fi</a>
                            </div>
                        </div>

                        <div class="one_half last" data- threshold="0 0" data-jarallax-element="120 0"
                            style="text-align:center; align:right; vertical-align:middle;">
                            <p class="title-description-up">NDT-TESTAAJA</p>
                            <h2 class="entry-title medium-text smaller-in-mobile" style="color:#fff;">
                                Antti Pasanen</h2>
                            <div style="margin-bottom: 2.5rem; font-size: 1.1rem;">
                                <h2 class="smaller-in-mobile"><a href="tel:+358404198607">040 419 8607</a></h2>
                                <a class="smaller-in-mobile"
                                    href="mailto:antti.pasanen@nswengineering.fi">antti.pasanen@nswengineering.fi</a>
                            </div>


                        </div>
                    </div>
                    <div class="clear"></div>

                </div>

            </div>
        </div>
    </div>

    <footer class="footer">
        <div class="footer-content center-relative" style="text-align: center;">
            <div class="footer-logo" style="text-align: center;">
                <div style="margin: auto; width: 60px; border: #fff 2px solid; ">NSW</div>
            </div>
            <div class="footer-logo-divider"></div>
            <div class="footer-mail"
                style="margin: auto; margin-top: -35px; display: inline-block; padding: 10px; border: #fff 2px solid; ">
                ENGINEERING
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                appleDevice: false
            }
        },
        mounted() {
            let check_iOS = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(
                /iPhone/i);
            let check_webkit = !!window.navigator.userAgent.match(/WebKit/i);
            this.appleDevice = check_iOS || check_webkit || !!window.navigator.userAgent.match(/CriOS/i);
            var $ = require("jquery");
            $('#header-main-menu ul li a[href^="#"], a.button, a.button-dot, .slow-scroll').on("click", function (e) {
                if ($(this).attr('href') === '#') {
                    e.preventDefault();
                } else {
                    if ($(window).width() < 1024) {
                        if (!$(e.target).is('.sub-arrow')) {
                            $('html, body').animate({
                                scrollTop: $(this.hash).offset().top - 76
                            }, 1500);
                            $('.menu-holder').removeClass('show');
                            $('#toggle').removeClass('on');
                            return false;
                        }
                    } else {
                        $('html, body').animate({
                            scrollTop: $(this.hash).offset().top - 76
                        }, 1500);
                        return false;
                    }
                }
            });
            var hash = location.hash;
            if ((hash != '') && ($(hash).length)) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - 77
                }, 1);
            }
        }
    }
</script>
<style>
    @import url("/css/clear.css");
    @import url("/css/sm-clean.css");
    @import url("/css/common.css");

    body {
        font-family: 'Rubik', sans-serif;
        font-size: 12px;
        line-height: 35px;
        font-weight: normal;
        color: #ffff;
        background-color: #ffffff;
        overflow-x: hidden;
    }

    body a {
        text-decoration: none;
        color: #fff;
        transition: color .3s ease;
    }

    body a:hover {
        color: red;
    }

    body p,
    body pre {
        margin-bottom: 13px;
    }

    .site-wrapper img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    .doc-loader {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 99999;
        background-color: #000;
    }

    .doc-loader img {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .content-1170 {
        width: 1170px;
    }

    .content-960 {
        width: 960px;
    }

    .content-570 {
        width: 570px;
    }

    .pagination-holder {
        margin: 60px 0;
    }

    .section-wrapper {
        position: relative;
        padding: 200px 0 115px 0;
    }

    .header-holder {
        z-index: 99 !important;
        background-color: #060606;
        width: 100% !important;
        top: 0;
        position: fixed;
        -webkit-transform: translateZ(0);
    }

    .sticky-wrapper {
        height: 77px !important;
    }

    .menu-wrapper {
        width: 98%;
        max-width: 1450px;
        min-height: 77px;
    }

    .header-logo {
        line-height: 1rem;
        font-size: 1rem;
        float: left;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
    }

    .header-logo img {
        width: 88px;
        height: 50px;
    }

    .menu-holder {
        float: right;
        max-width: 85%;
    }

    .toggle-holder {
        display: none;
        right: 10px;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    #toggle:hover {
        cursor: pointer;
    }

    #toggle div {
        height: 3px;
        margin-bottom: 6px;
        background-color: #fff;
        width: 22px;
    }

    #toggle .second-menu-line {
        -webkit-transition: -webkit-transform 0.3s ease;
        transition: transform 0.3s ease;
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }

    #toggle:hover .second-menu-line {
        -webkit-transform: translate3d(0, 0, 0) !important;
        transform: translate3d(0, 0, 0) !important;
    }

    #toggle.on .second-menu-line {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    #toggle .third-menu-line {
        margin-bottom: 5px;
    }

    #header-main-menu {
        display: inline-block;
    }

    .sm-clean .has-submenu {
        padding-right: 35px !important;
    }

    .sm-clean li a.menu-item-link {
        cursor: pointer;
        color: #ffffff;
    }

    .sm-clean a span.sub-arrow {
        right: 3px;
    }

    .sm-clean a.highlighted {
        padding: 15px;
    }

    .sm-clean a,
    .sm-clean a:hover,
    .sm-clean a:focus,
    .sm-clean a:active {
        font-size: 14px;
        font-weight: 400;
        transition: color .3s ease,
            background-color .3s ease;
        padding: 15px;
        letter-spacing: 0.5px;
        color: #fff;
    }

    .sm-clean ul a,
    .sm-clean ul a:hover,
    .sm-clean ul a:focus,
    .sm-clean ul a:active {
        font-size: 13px;
        line-height: 15px;
        padding: 10px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .sm-clean {
        background-color: transparent;
        padding: 15px 0;
    }

    body .sm-clean a:hover,
    body .main-menu.sm-clean .sub-menu li a:hover,
    body .sm-clean li.active a,
    body .sm-clean li.current-page-ancestor>a,
    body .sm-clean li.current_page_ancestor>a,
    body .sm-clean li.current_page_item>a {
        color: red;
    }

    .sm-clean li {
        margin-right: 10px;
    }

    .sm-clean li:last-child {
        margin-right: 0;
    }

    .sm-clean ul {
        background-color: #000;
    }

    .sm-clean a.has-submenu {
        padding-right: 24px;
    }

    .sub-menu a,
    .children a {
        padding-left: 15px !important;
    }

    .sub-menu .sub-menu,
    .children .children {
        padding-top: 8px;
    }

    .sub-menu .sub-menu a,
    .children .children a,
    .sub-menu .sub-menu a:hover,
    .children .children a:hover {
        padding-top: 10px !important;
    }

    @media (min-width: 925px) {

        .sm-clean ul a span.sub-arrow {
            margin-top: 0;
        }

        .sm-clean ul {
            padding: 8px 0;
        }
    }

    @media (max-width: 925px) {

        .sm-clean ul {
            background-color: #fff;
        }

    }

    .sm-clean>li:last-child>a,
    .sm-clean>li:last-child>*:not(ul) a,
    .sm-clean>li:last-child>ul,
    .sm-clean>li:last-child>ul>li:last-child>a,
    .sm-clean>li:last-child>ul>li:last-child>*:not(ul) a,
    .sm-clean>li:last-child>ul>li:last-child>ul,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
    .sm-clean>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
        border-radius: 5px;
    }

    .section {
        position: relative;
    }

    .page-title-holder {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 285px;
        display: inline-block;
        z-index: 98;
        height: 130px;
    }

    .page-title-holder:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 130px 60px 0 0;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
    }

    .section h3.entry-title {
        font-size: 18px;
        line-height: 149%;
        font-weight: 400;
        word-break: break-word;
        text-align: center;
        color: #000;
        letter-spacing: 3px;
        margin-top: 32px;
    }

    .page .page-content {
        background-color: #fff;
        padding: 75px;
    }

    .no-padding .section-wrapper {
        padding: 0 !important;
        width: 100%;
        max-width: 100%;
    }

    [data-jarallax-element] {
        z-index: 30 !important;
    }

    .medium-text {
        color: #000;
        font-size: 60px;
        line-height: 120%;
        font-weight: 700;
        padding-bottom: 35px;
    }
    .title-description-up {
        font-size: 16px;
        margin-bottom: 0;
        letter-spacing: 2px;
    }

    .info-text {
        font-size: 22px;
        line-height: 190%;
        color: #000;
    }

    body .social a:hover {
        opacity: 0.5;
    }


    .one_half,
    .one_third,
    .two_third,
    .three_fourth,
    .one_fourth {
        margin-right: 8%;
        float: left;
        position: relative;
        margin-bottom: 30px;
    }

    .last {
        margin-right: 0 !important;
        clear: right;
    }

    .one {
        display: block;
        clear: both;
        margin-bottom: 30px;
    }

    .one_half {
        width: 46%;
    }

    .one_third {
        width: 28%;
    }

    .two_third {
        width: 64%;
    }

    .one_fourth {
        width: 19%;
    }

    .three_fourth {
        width: 73%;
    }

    .one_half.margin-0 {
        width: 50%;
    }

    .one_third.margin-0 {
        width: calc(100% / 3);
    }

    .two_third.margin-0 {
        width: calc(100% / 3 * 2);
    }

    .one_fourth.margin-0 {
        width: 25%;
    }

    .three_fourth.margin-0 {
        width: 75%;
    }


    body a.button {
        display: inline-block;
        color: #000;
        background-color: #ffffff;
        text-align: left;
        padding: 10px 40px;
        cursor: pointer;
        vertical-align: middle;
        text-decoration: none;
        transition: all .2s linear;
        margin-bottom: 17px;
        border: 2px solid #000;
        border-radius: 0px;
        font-size: 14px;
        letter-spacing: 2px;
        box-sizing: border-box;
    }

    a.button:hover {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
    }

    a.button-dot {
        color: #fff !important;
        font-size: 16px;
        position: relative;
    }

    a.button-dot:hover {
        color: #fff;
    }

    a.button-dot span {
        transition: .3s;
        display: inline-block;
        margin-left: 50px;
        letter-spacing: 2px;
    }

    a.button-dot:hover span {
        transform: translateX(-8px);
    }

    a.button-dot:before {
        content: "";
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        background: transparent;
        border-radius: 90%;
        top: -7px;
        position: absolute;
        transition: .3s;
    }

    a.button-dot:hover:before {
        width: calc(100% + 30px);
        height: 51px;
        border-radius: 50px;
        top: -18px;
    }




    #alku {
        overflow: hidden;
        min-height: 100vh;
        display: flex;
        padding: 0;
        position: relative;
        background: #eee;
    }

    .container {
        background: #eee;
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
    }

    #alku iframe {
        box-sizing: border-box;
        height: 56.25vw;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh;
    }

    #alku .section-wrapper {
        margin: auto;
        padding: 0;
    }

    h1.big-text {
        color: #fff;
        font-size: 80px;
        line-height: 120%;
        margin-bottom: 40px;
        font-family: 'maverick';
    }

    .alphalayer {
        background-color: rgba(20, 20, 20, 0.9);
        color: #fff;
    }

    .alphapadding {
        padding: 2rem;
        border: red 2px solid;
    }

    .alpha-ota-yhteytta {
        position: absolute;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        min-width: 100%;
        background-color: rgba(20, 20, 20, 0.5);
        z-index: 1;
    }

    .alpha-ndt-testauspalvelut {
        position: absolute;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        min-width: 100%;
        background-color: rgba(20, 20, 20, 0.5);
        z-index: 1;
    }

    #ota-yhteytta {
        min-height: 100vh;
    }

    .alpha-yritys {
        position: absolute;
        height: 100%;
        width: 100%;
        min-height: 100vh;
        min-width: 100%;
        background-color: rgba(20, 20, 20, 0.5);
        z-index: 1;
    }

    #mapframe {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        min-height: 100vh;
    }

    #ndt-testauspalvelut {
        min-height: 100vh;
        background-image: url(/images/nsw-engineering-bg-factory.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .service-holder {
        text-align: center;
        margin: 0px 0;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .service-holder:after {
        content: "";
        display: block;
        clear: both;
    }

    .service-txt h4 {
        font-size: 1rem;
        color: #fff;
        line-height: 110%;
    }

    .service-num {
        font-size: 100px;
        font-weight: 100;
        color: rgba(0, 0, 0, 0);
        text-shadow: -1px 0 #fff,
            0 1px #fff,
            1px 0 #fff,
            0 -1px #fff;
        line-height: 100%;
        padding-left: 5px;

        height: 105px;
        overflow: hidden;
        margin-bottom: -10px;
        -webkit-transform: translateZ(0);
    }

    #yritys {
        min-height: 100vh;
        background-image: url(/images/nsw-engineering-bg-engine.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }


    .ota-yhteytta-form {
        min-height: 100vh;
        max-width: 100%;
        margin: 0 auto;
        font-size: 22px;
        color: #FFF;
    }

    .ota-yhteytta-form p {
        margin-bottom: 0 !important;
    }

    .ota-yhteytta-form input[type=text],
    .ota-yhteytta-form input[type=email],
    .ota-yhteytta-form textarea {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        border: 0;
        font-size: 15px;
        padding: 5px 0;
        width: 100%;
        line-height: 25px;
        color: #FFF;
        margin-bottom: 35px;
        text-indent: 10px;
        background-color: transparent;
        border-bottom: 3px solid;
    }

    .ota-yhteytta-form textarea {
        height: 150px;
    }

    .ota-yhteytta-form input[type=text]::placeholder,
    .ota-yhteytta-form input[type=email]::placeholder,
    .ota-yhteytta-form textarea::placeholder {
        font-family: 'Rubik', sans-serif;
        color: #FFF;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        opacity: 1;
    }

    .ota-yhteytta-form input[type=text]:ms-input-placeholder,
    .ota-yhteytta-form input[type=email]:ms-input-placeholder,
    .ota-yhteytta-form textarea:ms-input-placeholder {
        font-family: 'Rubik', sans-serif;
        color: #FFF;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
    }

    .ota-yhteytta-form input[type=text]::ms-input-placeholder,
    .ota-yhteytta-form input[type=email]::ms-input-placeholder,
    .ota-yhteytta-form textarea::ms-input-placeholder {
        font-family: 'Rubik', sans-serif;
        color: #FFF;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
    }

    .ota-yhteytta-form input[name="your-name"] {
        margin-top: 0;
    }

    .ota-yhteytta-submit-holder {
        position: relative;
        margin-bottom: 25px;
    }

    .ota-yhteytta-form input[type=submit] {
        color: #000;
        background-color: #fff;
        text-align: center;
        padding: 10px 40px;
        cursor: pointer;
        vertical-align: middle;
        text-decoration: none;
        transition: all .2s linear;
        margin-bottom: 17px;
        display: block;
        width: 100%;
        line-height: 35px;
        border: 1px solid black;
        font-size: 14px;
        letter-spacing: 2px;
        box-sizing: border-box;
    }

    .ota-yhteytta-form input[type=submit]:hover {
        background-color: #fff;
        color: red;
    }

    footer a {
        color: #fff;
    }

    footer ul {
        list-style: none;
        padding-left: 0;
        margin-left: 0;
    }

    .footer {
        font-size: 16px;
        line-height: 36px;
        padding-top: 75px;
        padding-bottom: 110px;
        clear: both;
        color: #fff;
        text-align: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center top;
        background-color: #111;
    }

    .footer-content {
        width: 900px;
    }

    .footer-logo img {
        width: 88px;
        height: 50px;
        margin-bottom: 30px;
    }

    .footer-logo-divider {
        position: relative;
        height: 60px;
        margin-bottom: 30px;
    }

    .footer-logo-divider:before {
        content: "";
        width: 2px;
        height: 60px;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: calc(50% - 1px);
    }

    .footer-mail {
        font-size: 46px;
        margin-bottom: 35px;
        line-height: 100%;
        font-weight: 700;
    }

    .footer-social-divider {
        position: relative;
        height: 62px;
        margin-bottom: 35px;
    }

    footer .footer-social-divider:after {
        content: "";
        position: absolute;
        width: 300px;
        height: 2px;
        background-color: white;
        left: calc(50% - 150px);
        bottom: 0;
    }

    footer .footer-social-divider:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 60px;
        background-color: white;
        left: calc(50% - 1px);
        top: 0;
    }

    .social-holder {
        margin-bottom: 35px;
    }

    .social-holder a {
        font-size: 28px;
        margin-right: 25px;
    }

    .social-holder a:last-of-type {
        margin: 0;
    }

    .mobilespacer {
        display: none;
    }

    /* ===================================
    17. Responsive CSS
====================================== */


    @media screen and (max-width: 1600px) {

        .content-1170,
        .content-960,
        .content-570 {
            max-width: 90%;
        }

    }

    @media screen and (max-width: 1220px) {

        blockquote {
            font-size: 17px;
            line-height: 28px;
        }

    }

    @media screen and (max-width: 1024px) {
        #yritys #ota-yhteytta #ndt-testauspalvelut .ota-yhteytta-form {
            min-height: 100vh !important;
        }

        .alpha-yritys {
            position: relative !important;
            min-height: none !important;
        }

        .hideatmobile {
            display: none !important;
        }

        .innerhalf {
            float: left !important;
        }

        .service-holder {
            display: block !important;
            width: 40% !important;
            height: 150px !important;
            margin: 0px !important;
            padding: 0.5rem !important;
        }

        .service-holder .service-num {
            margin: 0px !important;
            padding: 0px !important;
        }

        .service-holder .service-text {
            margin: 0px !important;
            padding: 0px !important;
        }

        .big-text {
            font-size: 0.5rem;
        }

        .sm-clean li {
            margin-right: 8px;
        }

        .sm-clean a,
        .sm-clean a:hover,
        .sm-clean a:focus,
        .sm-clean a:active,
        .sm-clean a.highlighted {
            padding: 15px 8px;
        }

        .footer-content {
            width: 100%;
        }

        .one_half,
        .one_third,
        .two_third,
        .one_fourth,
        .three_fourth {
            margin-right: 6%;
        }

        .one_half {
            width: 47%;
        }

        .one_third {
            width: calc(88% / 3);
        }

        .two_third {
            width: calc(194% / 3);
        }

        .one_fourth {
            width: calc(82% / 4);
        }

        .three_fourth {
            width: calc(318% / 4);
        }

        .section h3.entry-title {
            margin-bottom: 0 !important;
        }

        .sticky-spacer {
            height: auto !important;
            width: auto !important;
            position: relative !important;
        }
    }

    @media screen and (max-width: 1020px) {

        #header-main-menu {
            padding-bottom: 70px;
        }

        .menu-wrapper {
            width: 95%;
        }

        .main-menu ul {
            width: 12em;
        }

        .toggle-holder {
            display: block;
        }

        .sm-clean li {
            margin: 0 auto !important;
            border: 0;
        }

        .sm-clean a.has-submenu {
            display: inline-block;
        }

        .menu-holder {
            position: fixed;
            text-align: center;
            background-color: #000000;
            right: -100%;
            top: 77px;
            transition: right .3s ease;
            height: 100%;
            width: 300px;
            overflow: auto;
            max-width: 100%;
        }

        .header-holder {
            -webkit-transform: none;
        }

        .menu-holder.show {
            right: 0;
        }

        .sm-clean ul ul a,
        .sm-clean ul ul a:hover,
        .sm-clean ul ul a:focus,
        .sm-clean ul ul a:active {
            border: none;
        }

        .sm-clean a span.sub-arrow {
            background: transparent;
        }

        .sm-clean a,
        .sm-clean a:hover,
        .sm-clean a:focus,
        .sm-clean a:active,
        .sm-clean a.highlighted {
            padding: 10px 18px 10px 18px;
            padding-left: 10px !important;
            margin-bottom: 5px;
        }

        h1.big-text {
            text-align: center;
            font-size: 60px;
        }

        .section-wrapper {
            padding-bottom: 70px;
        }

        .footer {
            padding-top: 30px;
        }

        .form-submit {
            padding-bottom: 20px;
        }

        .one_half,
        .one_third,
        .one_fourth,
        .two_third,
        .three_fourth {
            width: 100% !important;
            float: none;
            margin-right: auto !important;
            margin-left: auto !important;
            text-align: center;
        }

        .margin-0 img {
            width: 100% !important;
        }

        .grid-item,
        .grid-sizer {
            width: 100% !important;
        }

        .button-holder {
            text-align: center !important;
        }

        .service-holder {
            text-align: center;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        [data-jarallax-element] {
            transform: none !important;
        }

        .no-page-title .section-wrapper {
            padding: 35px 0 35px 0;
        }

        .text-slider-wrapper {
            padding: 10%;
        }

        .medium-text {
            font-size: 40px;
            line-height: 105%;
            padding-top: 10px;
        }

        .close-icon {
            top: 120px;
            right: -10px;
            left: auto;
        }

        .single .nav-links {
            transform: none;
            margin-top: 50px;
            text-align: center;
        }

    }

    @media screen and (max-width: 767px) {
        .hideatmobile {
            display: none;
        }

        .smaller-in-mobile {
            font-size-adjust: -0.5rem;
            padding: 0.2rem;
            margin: 0px;
        }

        .page-title-holder {
            height: 80px;
        }

        .section h3.entry-title {
            margin-top: 18px;
        }

        .service-holder {
            margin: 0 auto;
        }

        .section-wrapper {
            padding: 20px 0 30px 0;
        }

        .page-title-holder:after {
            border-width: 100px 60px 0 0;
        }

        blockquote {
            max-width: 100%;
            margin-top: 25px;
        }

        blockquote:before {
            position: relative;
            left: 0;
            font-size: 180px;
            line-height: 37px;
            top: 0;
        }

    }

    @media screen and (max-width: 750px) {

        .blog-item-holder .entry-holder {
            max-width: 95%;
        }

    }

    @media screen and (max-width: 650px) {
        h1.big-text {
            text-align: center;
            font-size: 40px;
        }
    }


    @media screen and (max-width: 400px) {
        .page-title-holder {
            width: 200px;
        }
    }
</style>